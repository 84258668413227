export const sjbhxyHtml = `
<p class="MsoNormal">
<b>最新更新日期<span>2024</span>年<span>1</span>月<span>1</span>日<span></span></b>
</p>
<p class="MsoNormal">
引言<span></span>
</p>
<p class="MsoNormal">
南京百数来信息科技有限公司（以下称“速调吧”或“我们”）非常重视用户个人信息的保护。您在下载、开启、浏览、注册、登录、使用“速调吧”相关网站（<span>www.sudiaoba.com</span>）、速调吧软件<span>APP</span>及相关产品和<span>/</span>或服务时，我们可能会收集和使用您的相关信息。我们希望通过本《数据保护协议》向您说明，我们将如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。<span></span>
</p>
<p class="MsoNormal">
<b><u>在使用速调吧各项产品或服务之前，请您务必仔细阅读并透彻理解本协议，在确认充分理解后作出适当选择。勾选“我接受速调吧数据保护协议”按钮（或其他表示您接受该协议的按钮）表示您同意我们按照本《数据保护协议》收集、使用、储存和分享您的相关信息。一旦您使用或在我们更新本《数据保护协议》后继续使用我们的产品或服务，即意味着您同意本《数据保护协议》及更新后内容并同意我们按照本《数据保护协议》处理您的相关个人信息。</u></b><span></span>
</p>
<p class="MsoNormal">
本《数据保护协议》包含以下内容：<span></span>
</p>
<p class="MsoNormal">
<span>1 </span>我们可能收集的信息<span></span>
</p>
<p class="MsoNormal">
<span>2 </span>我们如何存储您的信息<span></span>
</p>
<p class="MsoNormal">
<span>3 </span>我们如何使用<span>Cookie</span>和同类技术<span></span>
</p>
<p class="MsoNormal">
<span>4 </span>我们如何使用信息<span></span>
</p>
<p class="MsoNormal">
<span>5 </span>您如何访问和控制自己的个人信息<span></span>
</p>
<p class="MsoNormal">
<span>6 </span>我们如何共享信息<span></span>
</p>
<p class="MsoNormal">
<span>7 </span>信息安全<span></span>
</p>
<p class="MsoNormal">
<span>8 </span>您分享的信息<span></span>
</p>
<p class="MsoNormal">
<span>9 </span>第三方服务<span></span>
</p>
<p class="MsoNormal">
<span>10 </span>我们可能向您发送的邮件和信息<span></span>
</p>
<p class="MsoNormal">
<span>11 </span>未成年人使用我们的产品和<span>/</span>或服务<span></span>
</p>
<p class="MsoNormal">
<span>12 </span>数据保护协议的适用范围<span></span>
</p>
<p class="MsoNormal">
<span>13 </span>变更<span></span>
</p>
<p class="MsoNormal">
<span>1 </span>我们可能收集的信息<span></span>
</p>
<p class="MsoNormal">
我们提供产品和<span>/</span>或服务时，可能会收集、储存下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法使用和享受我们提供的某些产品和服务，或者无法达到相关产品和<span>/</span>或服务拟达到的效果。<span></span>
</p>
<p class="MsoNormal">
<b><span>1.1 </span>您在使用我们服务时主动提供的信息<span></span></b>
</p>
<p class="MsoNormal">
<span>1.1.1 </span>当您注册速调吧账号或使用速调吧提供的产品和<span>/</span>或服务时，您需要向我们提供您的<b>姓名、手机号码、电子邮箱以及我们要求您提供的其他必要个人信息。</b><span></span>
</p>
<p class="MsoNormal">
<span>1.1.2 </span>为了给您提供完善的产品和<span>/</span>或专属服务，速调吧可能会要求您提供您的<b>姓名、电子邮件地址、实际地址、微信号、性别、语言、年龄等个人信息</b>，速调吧需要这些数据以便快速便利地为您提供相关产品和<span>/</span>或服务，并不断优化此产品和<span>/</span>或服务内容以便更能符合您的使用需求。<span></span>
</p>
<p class="MsoNormal">
<span>1.1.3 </span>在您事先同意的情况下，速调吧还可能会要求您提供您的<b>个人简历数据（如职业、教育、年龄及性别等），</b>以便提升速调吧的产品、服务、宣传及营销成效。速调吧可能会利用此类个人简历数据，来制作有关用户群的整体报告，此类个人简历数据及整体报告皆为不具名形式，不会包含能识别您的内容。<span></span>
</p>
<p class="MsoNormal">
<span>1.1.4 </span>在购买任一产品和<span>/</span>或服务时，如您选择以信用卡或者银行卡方式支付费用，在选择特定支付方式时您需要提供您的<b>信用卡或银行卡信息</b>给我们，以便您购买想要的产品和<span>/</span>或服务。在您的支付过程中，速调吧不会向第三方提供有关您个人信用卡或银行卡上之隐私资料。<b>您支付时直接跳转到第三方支付页面的，该等第三方将会直接搜集您的支付信息，该等信息的收集、使用、储存和分享将遵守第三方的隐私条款，与速调吧无关，请您注意事先仔细查看。</b><span></span>
</p>
<p class="MsoNormal">
<span>1.1.5 </span>除上述信息外，速调吧还可能为了提供产品和<span>/</span>或服务及提高产品和<span>/</span>或服务质量之必需，收集您的其他信息以及您与速调吧及其关联公司的工作人员沟通时所提供的相关信息。<span></span>
</p>
<p class="MsoNormal">
<span>1.1.6 </span>当您参加速调吧的有关市场营销活动时，我们根据活动需要可能会收集您的<b>姓名、通信地址、联系方式、微信账号等信息。</b><span></span>
</p>
<p class="MsoNormal">
<b><span>1.2 </span>您使用速调吧产品和<span>/</span>或服务时，我们获取的您的信息<span></span></b>
</p>
<p class="MsoNormal">
<b>您在使用速调吧产品和<span>/</span>或服务时，我们可能收集如下信息：</b><span></span>
</p>
<p class="MsoNormal">
<span>1.2.1&nbsp;</span><b>日志信息，</b>指您使用我们的产品和<span>/</span>或服务时，我们的系统可能通过<span>cookies</span>、<span>webbeacon</span>或其他方式自动采集的技术信息，包括：<span></span>
</p>
<p class="MsoNormal">
<span>a)&nbsp;</span><b>设备或软件信息，</b>例如您的移动设备、网页浏览器或用于接入我们产品和<span>/</span>或服务的其他程序所提供的配置信息、移动设备所用的版本和设备识别码、设备所在位置相关信息（<b>例如<span>IP</span>地址、<span>GPS</span>位置以及能够提供相关信息的<span>WLAN</span>接入点、蓝牙和基站等传感器信息</b>）等；<span></span>
</p>
<p class="MsoNormal">
<span>b) </span>在使用我们产品和<span>/</span>或服务时搜索或浏览的信息，例如您使用的<b>网页搜索词语、访问的社交媒体页面<span>url</span>地址</b>，以及您在使用我们产品和<span>/</span>或服务时浏览或要求提供的其他信息和内容详情等；<span></span>
</p>
<p class="MsoNormal">
<span>c)&nbsp;</span><b>有关您曾使用的移动应用（<span>APP</span>）和其他软件的信息；</b><span></span>
</p>
<p class="MsoNormal">
<span>d)&nbsp;</span><b>您通过我们的产品和<span>/</span>或服务进行通讯的信息，例如曾通讯的账号，以及通讯时间、数据和时长等；</b><span></span>
</p>
<p class="MsoNormal">
<span>e)&nbsp;</span><b>您通过我们的产品和<span>/</span>或服务分享的内容所包含的信息（元数据），例如拍摄或上传的照片、录音或录像的日期、时间或地点等。</b><span></span>
</p>
<p class="MsoNormal">
<span>f)&nbsp;</span><b>我们可能会获得您在使用第三方合作伙伴时所产生或分享的信息。</b>例如，您使用微信或<span>QQ</span>账户登录第三方合作伙伴服务时，我们会获得您登录第三方合作伙伴服务的名称、登录时间，方便您进行授权管理。请您仔细阅读第三方合作伙伴的服务协议或隐私协议。<span></span>
</p>
<p class="MsoNormal">
<span>1.2.2&nbsp;</span><b>位置信息</b>，包括您或其他用户在使用速调吧产品和<span>/</span>或服务时向我们提供的包含您所处地理位置的信息。<span></span>
</p>
<p class="MsoNormal">
<span>1.2.3&nbsp;</span><b>为了给您提供完善的产品和<span>/</span>或专属服务，您在使用我们的产品和<span>/</span>或服务时系统所收集的信息，例如为向您提供更契合您个人的调研，我们可能会收集关于您使用的服务以及使用方式的个人信息并将这些个人信息进行关联。</b><span></span>
</p>
<p class="MsoNormal">
<span>2 </span>我们如何存储您的信息<span></span>
</p>
<p class="MsoNormal">
我们在中华人民共和国境内运营中收集和产生的个人信息，原则上存储在中国境内，以下情形除外：<span></span>
</p>
<p class="MsoNormal">
<span>a) </span>法律法规有明确规定；<span></span>
</p>
<p class="MsoNormal">
<span>b) </span>获得您的明确授权。<span></span>
</p>
<p class="MsoNormal">
针对以上情形，我们会确保依据法律法规和本《数据保护协议》对您的个人信息提供安全保护。<span></span>
</p>
<p class="MsoNormal">
<span>3 </span>我们如何使用<span>Cookie</span>和同类技术<span></span>
</p>
<p class="MsoNormal">
<span>3.1 </span>我们或我们的第三方合作伙伴，可能通过<span>cookies</span>收集和使用您的信息，并将该等信息储存为日志信息。<span></span>
</p>
<p class="MsoNormal">
<span>3.2 </span>我们使用自己的<span>cookies</span>，目的是为您提供更个性化的用户体验和产品和<span>/</span>或服务，并用于以下用途：<span></span>
</p>
<p class="MsoNormal">
<span>3.2.1&nbsp;</span><b>记住您的身份</b>。例如：<span>cookies</span>有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息；<span></span>
</p>
<p class="MsoNormal">
<span>3.2.2&nbsp;</span><b>分析您使用我们产品和<span>/</span>或服务的情况</b>。例如，我们可利用<span>cookies</span>来了解您使用我们的产品和<span>/</span>或服务进行什么活动，或哪些网页或产品和<span>/</span>或服务最受您的欢迎；<span></span>
</p>
<p class="MsoNormal">
<span>3.2.3&nbsp;</span><b>广告优化</b>。<span>Cookies</span>和<span>webbeacon</span>有助于我们根据您的信息，向您提供与我们的产品和<span>/</span>或服务相关的广告而非进行普遍的广告。<span></span>
</p>
<p class="MsoNormal">
<span>3.3 </span>我们为上述目的使用<span>cookies</span>的同时，可能将通过<span>cookies</span>收集的非个人身份信息，经统计加工后提供给广告商和其他合作伙伴，用于分析用户如何使用我们的产品和<span>/</span>或服务，并用于我们的广告服务。<span></span>
</p>
<p class="MsoNormal">
<span>3.4 </span>我们的产品和服务上可能会有广告商或其他合作方放置的<span>cookies</span>和<span>webbeacon</span>。这些<span>cookies</span>和<span>webbeacon</span>可能会收集与您相关的非个人身份信息，以用于分析用户如何使用该等产品和<span>/</span>或服务、向您发送您可能感兴趣的广告，或用于评估广告服务的效果。<b>这些第三方<span>cookies</span>和<span>webbeacon</span>收集和使用该等信息，不受本《数据保护协议》约束，而是受到其自身的数据保护协议约束，我们不对第三方的<span>cookies</span>或<span>webbeacon</span>承担责任。</b><span></span>
</p>
<p class="MsoNormal">
<span>3.5 </span>您可以通过浏览器设置拒绝或管理<span>cookies</span>。但请注意，如果停用<span>cookies</span>或<span>webbeacon</span>，我们有可能无法为您提供优质的服务体验，某些产品和<span>/</span>或服务也可能无法正常使用。<span></span>
</p>
<p class="MsoNormal">
<span>3.6 </span>为了确认您的使用形态与网站的使用状况，我们的网站会记录您设备的<span>IP</span>地址与浏览器的类型，用户的浏览行为和操作行为等，以协助我们改善速调吧的设计与编排格式。<span></span>
</p>
<p class="MsoNormal">
<span>4 </span>我们如何使用信息<span></span>
</p>
<p class="MsoNormal">
<b><span>4.1 </span>我们可能将在向您提供产品和<span>/</span>或服务的过程之中所收集的信息用作下列用途：</b><span></span>
</p>
<p class="MsoNormal">
<b><span>4.1.1 </span>向您提供产品和<span>/</span>或服务，我们所收集和使用的个人信息是为您提供速调吧服务的必要条件，如缺少相关个人信息，我们将无法为您提供速调吧服务的核心内容；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>4.1.2 </span>在我们提供产品和<span>/</span>或服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>4.1.3 </span>帮助我们设计新产品和<span>/</span>或服务，改善我们现有产品和<span>/</span>或服务；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>4.1.4 </span>使我们更加了解您如何接入和使用我们的产品和<span>/</span>或服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>4.1.5 </span>向您提供速调吧相关的产品和<span>/</span>或服务广告；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>4.1.6 </span>评估我们产品和<span>/</span>或服务中的广告和其他促销及推广活动的效果，并加以改善；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>4.1.7 </span>软件认证或管理软件升级；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>4.1.8 </span>让您参与有关我们产品和<span>/</span>或服务的调查，或者促销及推广等活动；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>4.1.9 </span>经您同意的其他用途。</b><span></span>
</p>
<p class="MsoNormal">
<span>4.2 </span>为了让您有更好的体验、改善我们的产品和<span>/</span>或服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项产品和<span>/</span>或服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他产品和<span>/</span>或服务。例如，在您使用我们的一项产品或服务时所收集的信息，可能在另一产品或服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关产品和<span>/</span>或服务中提供了相应选项，您也可以授权我们将该产品和<span>/</span>或服务所收集和储存的信息用于我们的其他产品和<span>/</span>或服务。<span></span>
</p>
<p class="MsoNormal">
<span>4.3 </span>为了给您提供完善的产品和<span>/</span>或服务，提升您的用户体验，在收集您的个人信息后，我们可能会对用户数据库进行分析并予以商业化的利用。我们将遵循法律法规和合法必要的原则，对相关信息采取必要的安保措施，比如通过技术手段对数据进行去标识化处理等。<span></span>
</p>
<p class="MsoNormal">
<b>您了解并同意，在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理后的个人信息将无法识别您的身份，在此情况下我们有权使用去标识化的个人信息，对用户数据库进行分析并予以商业化的利用。</b><span></span>
</p>
<p class="MsoNormal">
<span>5 </span>您如何访问和控制自己的个人信息<span></span>
</p>
<p class="MsoNormal">
<span>5.1 </span>您可以随时登录自己的账户查看或修改账户设置和信息。如果您希望变更、更新或删除我们系统中保留的您个人信息，您只需登入速调吧账户，在“账号信息”下进行操作或者通过客服与我们联系进行修改。我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的产品和<span>/</span>或服务时提供的其他个人信息。在访问、更新、更正、删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。<span></span>
</p>
<p class="MsoNormal">
<span>5.2 </span>您也可以通过速调吧微信公众号联系客服要求注销速调吧账户。如果您发现我们违反法律法规或者本数据保护协议约定收集、使用您的个人信息的，您可以通过客服与我们联系，要求我们删除相关个人信息，我们将采取措施将相关个人信息予以删除或进行匿名化或去标识化处理。在您主动注销账号之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化或去标识化处理。<span></span>
</p>
<p class="MsoNormal">
<span>5.3 </span>在您使用我们的产品和<span>/</span>或服务期间，我们会尽一切可能保证您可以顺利访问自己的账户内信息。如果这些账户内信息有误，我们会努力提供各种方式来让您快速更新或删除帐户内信息（除非我们出于合法业务或法律方面的原因而必须保留这些信息）。<span></span>
</p>
<p class="MsoNormal">
<span>6 </span>我们如何共享信息<span></span>
</p>
<p class="MsoNormal">
<span>6.1 </span>我们会以高度的勤勉义务对待您的信息，<b>除以下情形外，未经您同意，我们不会与任何第三方分享您的个人信息：</b><span></span>
</p>
<p class="MsoNormal">
<b>我们可能将上述我们收集的个人信息提供给我们的关联公司、合作伙伴及第三方服务提供方（例如代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商），用作下列用途：</b><span></span>
</p>
<p class="MsoNormal">
<b><span>a) </span>向您提供更好的客户服务和用户体验；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>b) </span>实现本《数据保护协议》第<span>4</span>条“我们如何使用信息”部分所述目的；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>c) </span>行使和履行在《数据保护协议》及其它我们和您之间的合同或规则中的权利和义务；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>d) </span>维护和改善我们的产品和<span>/</span>或服务。</b><span></span>
</p>
<p class="MsoNormal">
<span>6.2 </span>为提供更好的客户服务和用户体验，我们可能会与第三方提供您的个人信息。我们仅会出于合法、正当、必要的目的提供您的个人信息，并且只会提供相关产品和<span>/</span>或服务所必要的个人信息。我们将努力要求该等第三方在使用您的个人信息时遵守本《数据保护协议》及我们要求其遵守的其他适当的保密和安全措施，并明确要求该等第三方仅将共享的个人信息用于与速调吧产品和<span>/</span>或服务相关的特定用途。如要改变个人信息的处理目的，该等第三方将再次征求您的授权同意。<span></span>
</p>
<p class="MsoNormal">
<b><span>6.3 </span>随着我们业务的持续发展，我们有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移</b>。在转移之前，我们会提前进行告知，在获得您的明确同意后，我们才会向他方转让您的个人信息。<span></span>
</p>
<p class="MsoNormal">
<span>6.4 </span>我们还可能为以下需要而保留、保存或披露您的个人信息：<span></span>
</p>
<p class="MsoNormal">
<span>a) </span>根据法律、法规或有权部门的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下；<span></span>
</p>
<p class="MsoNormal">
<span>b) </span>与国家安全、国防安全有关的；<span></span>
</p>
<p class="MsoNormal">
<span>c) </span>处于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<span></span>
</p>
<p class="MsoNormal">
<span>d) </span>为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们或我们的集团公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。<span></span>
</p>
<p class="MsoNormal">
<span>6.5 </span>我们可能与合作伙伴合作举办营销活动，并与其分享活动过程中产生的、为完成活动所必要的一些信息，以便您能参与活动、我们的合作伙伴能及时与您联系、发放奖品。<span></span>
</p>
<p class="MsoNormal">
<span>7 </span>信息安全<span></span>
</p>
<p class="MsoNormal">
<span>7.1 </span>我们仅在为本《数据保护协议》所述目的和法律法规允许的时限内保留您的个人信息。<span></span>
</p>
<p class="MsoNormal">
<span>7.2 </span>为了保障您的信息安全，速调吧公司会在现有技术水平下采取合理必要的措施来保护您的信息，包括物理、技术和行政管理安全措施来降低丢失、误用、非授权访问、披露和更改的风险，包括但不限于传输层数据加密、防火墙和加密存储、物理访问控制以及信息访问授权控制。<span></span>
</p>
<p class="MsoNormal">
<span>7.3 </span>在信用卡或银行卡数据透过网络传送给速调吧之前，这些数据都经过速调吧的安全服务器予以加密处理。在登入时，速调吧也坚持使用密码认证，避免您的个人资料在未经授权的状况下被存取。<span></span>
</p>
<p class="MsoNormal">
<span>7.4 </span>您在使用速调吧产品和<span>/</span>或服务时，请妥善保管好您的速调吧账号及其密码，我们会通过您的账号及其密码来识别您的身份。<b>使用账号和秘密进行的任何操作、发出的指令都视为您本人做出，因您自身原因造成的账户、密码等信息被冒用、盗用或非法使用，由此造成的风险和损失由您自行承担。</b>一旦您发现速调吧账号及其密码因任何原因已经或将受到泄漏时，请您立即联络我们客服，以便我们采取相应措施，<b>但在我们知悉此种情况和在合理时间内采取行动前，我们对此不负任何责任。</b><span></span>
</p>
<p class="MsoNormal">
<span>7.5 </span>在不幸发生个人信息安全事件后，我们将按照法律法规的要求及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。<b>请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证个人信息百分之百的安全。请您理解并明白，由于存在各种各样无法预知情况，您接入速调吧服务所用的系统和通讯网络，有可能因我们可控范围之外的情况而发生问题。</b><span></span>
</p>
<p class="MsoNormal">
<span>8 </span>您分享的信息<span></span>
</p>
<p class="MsoNormal">
<span>8.1 </span>我们的产品和<span>/</span>或服务，可让您不仅与自己的社交网络，也与使用该产品和<span>/</span>或服务的其他用户公开分享您的相关信息，例如，您在我们的产品和<span>/</span>或服务中所上传或发布的信息（包括您公开的个人信息）、您对其他人上传或发布的信息作出的回应，以及包括与这些信息有关的位置数据和日志信息。使用我们产品和<span>/</span>或服务的其他用户也有可能分享与您有关的信息（包括位置数据和日志信息）。只要您不删除共享信息，有关信息会一直留存在公共领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的非关联第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公共领域保存。<span></span>
</p>
<p class="MsoNormal">
<b>因此，请您谨慎考虑，是否通过我们的产品和<span>/</span>或服务上传、发布和交流相关信息内容。在一些情况下，您可通过联系我们要求从我们的产品和<span>/</span>或服务中删除或者修改您的相关信息。</b><span></span>
</p>
<p class="MsoNormal">
<span>8.2 </span>某些个人信息因其特殊性可能被认为是个人敏感信息，例如您的种族、宗教、个人健康和医疗信息等。相比其他个人信息，个人敏感信息受到更加严格的保护。<span></span>
</p>
<p class="MsoNormal">
<b><span>8.3 </span>请注意，您在使用我们的产品和<span>/</span>或服务时所提供、上传或发布的内容和信息（例如有关您个人的照片等信息），可能会泄露您的个人敏感信息。您需要谨慎地考虑，是否在使用我们的产品和<span>/</span>或服务时披露相关个人敏感信息。</b><span></span>
</p>
<p class="MsoNormal">
<b><span>8.4 </span>您同意我们可以按本《数据保护协议》所述的目的和方式来处理您的个人敏感信息。</b><span></span>
</p>
<p class="MsoNormal">
<span>9 </span>第三方服务<span></span>
</p>
<p class="MsoNormal">
<span>9.1 </span>我们可能链接至第三方提供的社交媒体，您也可利用“分享”将速调吧某些产品或服务分享至第三方平台。<span></span>
</p>
<p class="MsoNormal">
<span>9.2 </span>我们也可能使用您的信息，通过我们的产品和<span>/</span>或服务、电子邮件或其他方式向您发送营销信息，提供或推广我们或第三方的如下产品和<span>/</span>或服务：<span></span>
</p>
<p class="MsoNormal">
<span>9.2.1 </span>我们的产品和<span>/</span>或服务，我们的关联公司和合作伙伴的产品和<span>/</span>或服务；<span></span>
</p>
<p class="MsoNormal">
<span>9.2.2 </span>第三方互联网服务供应商。<span></span>
</p>
<p class="MsoNormal">
<b><span>9.3&nbsp;</span><u>如您不希望我们将您的个人信息用作前述广告用途，您可以通过我们在广告中提供的相关提示，或在特定产品和<span>/</span>或服务中提供的指引，要求我们停止为上述用途使用您的个人信息，或者停止进行第三方分享。</u></b><span></span>
</p>
<p class="MsoNormal">
<span>10 </span>我们可能向您发送的邮件和信息<span></span>
</p>
<p class="MsoNormal">
<span>10.1 </span>邮件和信息推送<span></span>
</p>
<p class="MsoNormal">
您在使用我们的产品和<span>/</span>或服务时，我们可能使用您的信息向您的设备发送电子邮件、新闻或推送通知。如您不希望收到这些信息，可以按照我们的相关提示，在设备上选择取消订阅。<span></span>
</p>
<p class="MsoNormal">
<span>10.2 </span>与产品和<span>/</span>或服务有关的公告<span></span>
</p>
<p class="MsoNormal">
我们可能在必要时（例如因系统维护而暂停某一项服务时）向您发出与产品和<span>/</span>或服务有关的公告。您可能无法取消这些与产品和<span>/</span>或服务有关、性质不属于广告。<span></span>
</p>
<p class="MsoNormal">
<span>11 </span>未成年人使用我们的产品和<span>/</span>或服务<span></span>
</p>
<p class="MsoNormal">
<b><u>我们非常重视对未成年人信息的保护。若您是<span>18</span>周岁以下的未成年人，请在您的父母或监护人的指导下仔细阅读本《数据保护协议》，并在征得您的父母或监护人同意的前提下提交您的个人信息及使用我们的产品和<span>/</span>或服务。</u></b><span></span>
</p>
<p class="MsoNormal">
<span>12 </span>数据保护协议的适用范围<span></span>
</p>
<p class="MsoNormal">
<span>12.1 </span>除某些特定产品和服务外，速调吧旗下所有的产品和<span>/</span>或服务均适用本《数据保护协议》。这些特定产品和服务将适用特定的数据保护协议。针对某些特定产品和服务的特定数据保护协议，将更具体地说明我们在该等产品和服务中如何使用您的信息。该特定产品和<span>/</span>或服务的隐私政策<span>/</span>数据保护协议构成本《数据保护协议》的一部分。如相关特定服务的隐私政策<span>/</span>数据保护协议与本《数据保护协议》有不一致之处，适用该特定产品和<span>/</span>或服务的隐私政策<span>/</span>或数据保护协议。<span></span>
</p>
<p class="MsoNormal">
<b><span>12.2 </span>请您注意，本《数据保护协议》仅适用于我们所收集、保存、使用、共享、披露信息。除非本《数据保护协议》另有明确所指，本《数据保护协议》不适用于以下情况：</b><span></span>
</p>
<p class="MsoNormal">
<b><span>a) </span>通过我们的产品和<span>/</span>或服务而接入的第三方服务（包括任何第三方网站）收集的信息；</b><span></span>
</p>
<p class="MsoNormal">
<b><span>b) </span>通过在我们产品和<span>/</span>或服务中进行广告服务的其他公司或机构所收集的信息。</b><span></span>
</p>
<p class="MsoNormal">
<b>请您知悉：如果您浏览第三方网站或使用第三方的产品和／或服务时向该第三方提供您的个人信息，您的信息应当适用该第三方的隐私声明或类似协议，我们对任何第三方不当使用或披露由您提供的信息不承担任何法律责任，无论您登录或浏览上述网站、软件，或使用其产品和<span>/</span>或服务是否基于速调吧的链接或引导。</b><span></span>
</p>
<p class="MsoNormal">
<span>13 </span>变更<span></span>
</p>
<p class="MsoNormal">
<b>我们可能适时修订本《数据保护协议》的条款，该等修订构成本《数据保护协议》的一部分。如该等修订造成您在本《数据保护协议》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或通过其他我们认为合适的方式（包括向您发送电子邮件或以其他方式通知您）发布对本《数据保护协议》所做的变更。在该种情况下，若您继续使用我们的产品和<span>/</span>或服务，即表示同意受经修订的本《数据保护协议》的约束。</b><span></span>
</p>
<p class="MsoNormal">
如果您还有其他问题和建议，请联系我们。我们会始终致力于充分保护用户信息，并为您提供更优质的体验和服务。<span></span>
</p>
<p class="MsoNormal">
<span>&nbsp;</span>
</p>
                                `;
