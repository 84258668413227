<template>
  <div class="help-page">
    <div class="help-page-left-area">
      <div class="left-nav-title">{{ $t("help") }}</div>
      <el-menu :default-active="defaultType" class="menu" @select="select">
        <el-menu-item
          :index="i.id + ''"
          v-for="i in questionTypes"
          :key="i.sortBy"
        >
          <span class="menu-title">{{ i.nameCn }}</span>
          <i class="el-icon-arrow-right"></i>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="help-page-right-area">
      <div class="help-card help-card--search">
        <div class="help-card--search-title">{{ $t("find_help") }}</div>
        <div class="help-card--search-bar">
          <el-input
            :placeholder="$t('enter_content')"
            class="input-with-select"
            v-model="searchKey"
            @keyup.enter.native="search"
          >
            <el-button type="primary" round slot="append" @click="search">{{
              $t("search")
            }}</el-button>
          </el-input>
        </div>
      </div>
      <div
        v-if="questionList && questionList.length"
        style="min-height: 400px; margin-top: 20px"
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0)"
      >
        <div class="help-card" v-for="i in questionList" :key="i.id">
          <div class="help-card--title">{{ i.sortBy }}. {{ i.title }}</div>
          <div class="help-card--text" v-html="i.contents"></div>
        </div>
      </div>
      <div class="help-card" v-if="!questionList || !questionList.length">
        <div class="help-card--text">{{ $t("no_search_result") }}</div>
      </div>
    </div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import { get, isArray } from "lodash-es";
import { sjbhxyHtml } from "@/assets/i18n/sjbhxy.js";
export default {
  data() {
    return {
      questionTypes: [],
      defaultType: "",
      questionList: [],
      searchKey: "",
      loading: false
    };
  },
  async created() {
    try {
      window.document.scrollingElement.scrollTo(0, 0);
    } catch (e) {
      console.info("sudiaoba warning:", e);
    }
    this.searchKey = this.$route.query.key;
    const res = await this.$http.get("/api/v1/QuestionTypes");
    if (res.data && res.data.length > 0) {
      this.loading = true;
      this.questionTypes = res.data;
      const defaultType = get(res, "data[0].id", "").toString();
      this.defaultType = defaultType;
      this.currentType = defaultType;

      this.search();
    }
  },
  methods: {
    select(v) {
      this.currentType = v;
      this.searchKey = "";
      this.search();
    },
    async search() {
      this.loading = true;
      const questionsRes = await this.$http.get("/api/v1/Questions", {
        params: this.searchKey
          ? {
              keywords: this.searchKey
            }
          : {
              id: this.currentType
            }
      });
      this.questionList = isArray(questionsRes.data) ? questionsRes.data : null;
      this.loading = false;

      if (
        this.searchKey === "数据保护协议" ||
        this.searchKey === "數據保護協定"
      ) {
        const data = {
          id: 26,
          sortBy: 26,
          typeId: 2,
          title: "数据保护协议",
          contents: sjbhxyHtml
        };
        console.log("触发");
        this.questionList.push(data);
      }
    },
    routeChange() {
      try {
        window.document.scrollingElement.scrollTo(0, 0);
      } catch (e) {
        console.info("sudiaoba warning:", e);
      }

      this.searchKey = this.$route.query.key;
      this.search();
    }
  },
  watch: {
    $route: "routeChange"
  },
  computed: {}
};
</script>
